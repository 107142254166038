



















import {Component, Prop, Vue, Watch} from 'vue-property-decorator'

import {Action} from 'vuex-class'
import {ReservationResponse} from '@/lib/kepler/interfaces'
import ServiceMesh from '@/lib/serviceMesh'
import Utils from '@/utils'
import moment from 'moment'

@Component({
  components: {
    Plate: Utils.loadComponent('Plate'),
    Img: Utils.loadComponent('proxy/Image'),
    Icon: Utils.loadComponent('proxy/Icon'),
    Chip: Utils.loadComponent('proxy/Chip'),
    Layout: Utils.loadComponent('proxy/Layout'),
    Toolbar: Utils.loadComponent('proxy/Toolbar'),
    CircleStatus: Utils.loadComponent('CircleStatus'),
    GradientCard: Utils.loadComponent('GradientCard'),
    ToolbarTitle: Utils.loadComponent('proxy/ToolbarTitle'),
    ProgressLinear: Utils.loadComponent('proxy/ProgressLinear'),
  },
  name: 'ActiveReservationBar',
})
export default class ActiveReservationBar extends Vue {
  @Action('current') public current!: () => Promise<void | ReservationResponse[]>

  @Prop() public reservation!: ReservationResponse
  @Prop({type: Boolean, default: false}) public hideChevron!: boolean

  public isLate: boolean = false
  public howMuch: number = 0
  public progress: number | 'indeterminate' = 0
  public counter: NodeJS.Timer | null = null

  public get formattedCounter() {
    const d = moment.duration(Math.abs(this.howMuch), 'seconds')
    const distance = this.reservation.distance
    let dhm = this.howMuch < 0 ? '-' : ''
    if (d.years() > 0) {
      dhm += `${Math.abs(d.years())}Y `
    }
    if (d.days() > 0) {
      dhm += `${moment.duration(Math.abs(d.asDays()), 'days').humanize({d: 365})} `
    }
    if (d.hours()) {
      dhm += `${moment.duration(d.hours(), 'hours').humanize()} `
    }
    dhm += `${moment.duration(d.minutes(), 'minutes').humanize()} `

    if (!d.hours()) {
      dhm += `${Math.abs(d.seconds())}s`
    }

    if (this.progress < 0) {
      return this.$t('booking.free_time', {t: dhm})
    } else if (this.progress > 100) {
      return this.$t('booking.late_time', {t: dhm})
    } else {
      return dhm + (!!distance ? ` | ${this.$distance(distance)}` : '')
    }
  }

  protected get typeColor() {
    if (this.progress < 0) {
      return 'success'
    } else if (this.progress > 100) {
      return 'error'
    } else {
      return ServiceMesh.bmvtColor(this.reservation.vehicle_slot)
    }
  }

  @Watch('reservation', {immediate: true})
  public onReservationChange() {
    clearInterval(this.counter || undefined)
    const now = moment()
    const freeUntil = moment(this.reservation.free_until, 'YYYY-MM-DD HH:mm:ss')
    const start = moment(this.reservation.start, 'YYYY-MM-DD HH:mm:ss')
    const end = moment(this.reservation.end, 'YYYY-MM-DD HH:mm:ss')
    const free = now.isSameOrBefore(freeUntil)
    const late = now.isSameOrAfter(end)
      && this.reservation.type.endsWith('RT')
    if (free) {
      this.howMuch = freeUntil.diff(now, 'seconds')
      const totalFree = freeUntil.diff(start, 'seconds')
      this.progress = -Math.round(100 / totalFree * this.howMuch)
      this.counter = setInterval(() => {
        this.howMuch--
      }, 1000)
    } else if (late) {
      this.howMuch = now.diff(moment(this.reservation.end, 'YYYY-MM-DD HH:mm:ss'), 'seconds')
      this.progress = Math.round(100 / start.diff(end, 'minutes') * start.diff(now, 'minutes'))
      this.counter = setInterval(() => {
        this.howMuch++
      }, 1000)
    } else {
      this.howMuch = now.diff(moment(this.reservation.start, 'YYYY-MM-DD HH:mm:ss'), 'seconds')
      if (this.reservation.type.endsWith('RT')) {
        this.progress = Math.round(100 / start.diff(end, 'minutes') * start.diff(now, 'minutes'))
      } else {
        this.progress = 0
      }
    }
  }

  protected openReservationDetail() {
    this.$router.push({name: 'reservation', params: {id: this.reservation.id}})
  }
}
