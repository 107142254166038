import {BookingMode, BookRequest, Driver, ReservationResponse, ReservationStatus, VehicleSlot, VehicleType} from '@/lib/kepler/interfaces'
import {FlowInput} from '@/store/modules/flowInputs'
import moment from 'moment'
import countries from '@/lib/kepler/countries'
import {Choice} from '@/components/flow/flowFields/MultipleChoice.vue'

const driver: Driver = {
  id: 'N4Nbl4',
  main_driver: true,
  status: 'ACTIVE',
  profile_picture: 'https://core.staging.playmoove.com/api/frontoffice/v2/drivers/profile_picture?driver_id=N4Nbl4&token=b14b32ba9950367236865919bab2d7a5184410a8',
  username: 'user',
  title: 'Mr',
  name: 'Ajksdejf',
  surname: 'JFldf',
  birthdate: '1976-02-04',
  birthplace: 'Pirri',
  gender: 'M',
  email: '42@playmoove.com',
  phone: '3290006059',
  mobile: '+393290006050',
  address: {
    country: 'Belize',
    region: 'VT',
    city: 'Port Constantinstad',
    postal_code: '21198',
    street: 'Haven Streets Apt. 282',
    street_number: '102 ',
  },
}

const vehicleSlot: VehicleSlot = {
  id: 'g4xgLm',
  name: 'Via S. Tommaso D\'Aquino - Monreale',
  lot: {
    id: 'ZEdw40',
    name: 'Via S. Tommaso D\'Aquino - Monreale',
    picture: 'https://core.staging.playmoove.com/res/vehicle-404.png',
    position: {
      latitude: 39.248229,
      longitude: 9.118096,
    },
  },
  reservation_type: BookingMode.RT,
  distance: 2520.1457727561733,
  position: {
    latitude: 39.248229,
    longitude: 9.118096,
  },
  vehicle: {
    id: 'oaok1a',
    plate: 'FJ578DR',
    model: '108',
    brand: 'Peugeot',
    brand_logo: 'https://s3.staging.playmoove.com/playmoove-staging/vehicle-brands/peugeot-logo.png',
    door: 'LOCKED',
    color: 'White',
    fuel_type: 'PETROL',
    fuel_level: 39,
    category: {
      id: 'ZEdw40',
      name: 'City-Compact',
      description: 'Auto per tutti i giorni',
      type: VehicleType.CAR,
      damage_picture: 'https://s3.eu-west-2.amazonaws.com/playmoove-playcar-storage-public/damage-picture/Skoda_Fabia_BP.png',
      picture: '',
    },
    picture: 'https://s3.staging.playmoove.com/playmoove-staging/vehicles/108.png',
    attributes: [{
      id: 'g4xL41',
      name: 'helmet',
      slug: 'helmet',
      description: 'bike helmet',
      recommended: 0,
      value: 'helmet',
      type: 'string',
    }, {
      id: '1m2Jml',
      name: 'on board computer',
      slug: 'on-board-computer',
      description: 'on board computer',
      recommended: 0,
      value: 'yes',
      type: 'string',
    }],
    notes: [],
    instructions: [],
  },
  base_rate: {
    id: '84rOEW',
    name: 'ONE CITY COMPACT',
    plan: {
      id: '34v64K',
      name: 'ONE',
    },
    vehicle_category: {
      name: 'City-Compact',
      damage_picture: '',
      id: '00',
      description: 'string',
      type: VehicleType.CAR,
      picture: '',
    },
    booking_mode: '',
    disabled: 0,
    valid_from: null,
    valid_to: null,
    info: {
      title: "5€/ora - 0.35€/km",
      description: "",
      time_rate: "5|60",
      distance_rate: "0.35|1",
      hidden: false,
      usage_cost: null
    }
  },
}

const reservation: ReservationResponse = {
  id: 'EnLMkm',
  number: 8626,
  type: 'FF',
  start: '2018-12-04 15:11:45',
  end: '2018-12-11 15:11:45',
  status: ReservationStatus.COMPLETED,
  driver: driver,
  vehicle_slot: vehicleSlot,
  current_vehicle_position: {
    latitude: 39.223831,
    longitude: 9.12662,
  },
  trips: [{
    id: '4PllY4',
    start: '2018-12-04 22:23:34',
    end: '2018-12-04 22:32:05',
    latitude: 39.223831,
    longitude: 9.12662,
    distance: 1,
    speed: 7.05,
    fuel_level: 8,
    start_timestamp: 1543958614,
    end_timestamp: 1543959125,
  }],
  cost: null,
  memo: null,
  distance: 3,
  created: '2018-12-04 15:11:45',
  updated: '2018-12-05 10:40:58',
  start_timestamp: 1543932705,
  end_timestamp: 1544537505,
  created_timestamp: 1543932705,
  updated_timestamp: 1544002858,
  extra: {
    actions: [],
  },
  flags: [],
  reportings: [],
}

const bookRequest: BookRequest = {
  start: moment().format('YYYY-MM-DD HH:mm:ss'),
  end: moment().add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss'),
  plate: 'f4k3P14t3',
  memo: '',
  packet_id: null
}

const choices: Choice[] = [{
  value: 'spear',
  title: 'Spear',
  text: 'a reliable choice',
  icon: 'mdi-spear',
},{
  value: 'sword',
  title: 'Sword',
  text: 'fancy but effective',
  icon: 'mdi-sword'
},{
  value: 'axe',
  title: 'Axe',
  text: 'a bit crude but ok',
  icon: 'mdi-axe-battle'
},{
  value: 'mace',
  title: 'Mace',
  text: 'ooga booga',
  icon: 'mdi-mace'
}]

const devFlow: FlowInput = {
  steps: [
    {
      id: 'one',
      context: 'devFlow',
      title: 'WELCOME TO THE FLOW FIELDS',
      image: 'https://c.tenor.com/vo5NUYk1FwQAAAAC/joji-filthy-frank.gif',
      fields: [
        {
          id: 'string',
          name: 'string',
          type: 'string',
        }, {
          id: 'number',
          name: 'number',
          type: 'number',
        }, {
          id: 'name',
          name: 'name',
          type: 'name',
        }, {
          id: 'surname',
          name: 'surname',
          type: 'surname',
        }, {
          id: 'email',
          name: 'email',
          type: 'email',
        }, {
          id: 'phone',
          name: 'phone',
          type: 'phone',
        }, {
          id: 'password',
          name: 'password',
          type: 'password',
        }, {
          id: 'date',
          name: 'date',
          type: 'date',
        }, {
          id: 'dateTimeRange',
          name: 'dateTimeRange',
          type: 'dateTimeRange',
          // validation: `required|after :${moment().add(2, 'days').format('YYYY-MM-DD HH:mm')}|before:${moment().add(7, 'days').format('YYYY-MM-DD HH:mm')}`,
          validation: {
            required: true,
            after: moment().add(2, 'days').format('YYYY-MM-DD HH:mm'),
            before: moment().add(7, 'days').format('YYYY-MM-DD HH:mm')
          }
          }, {
          id: 'dateRange',
          name: 'dateRange',
          type: 'dateRange',
        }, {
          id: 'select',
          name: 'select',
          type: 'select',
          options: ['option', 'other option', 'horse'],
        }, {
          id: 'country',
          name: 'country',
          type: 'country',
          options: countries as any,
        }, {
          id: 'lang',
          name: 'lang',
          type: 'lang',
        }, {
          id: 'checkbox',
          name: 'checkbox',
          type: 'checkbox',
        }, {
          id: 'selfie',
          name: 'selfie',
          type: 'selfie',
        }, {
          id: 'image',
          name: 'image',
          type: 'image',
        }, {
          id: 'location',
          name: 'location',
          type: 'location',
          options: ['map'],
        }, {
          id: 'payment',
          name: 'payment method',
          type: 'paymentMethod',
          validation: 'always_edit',
        }, {
          id: 'choice',
          name: 'Choose a weapon',
          type: 'multipleChoice',
          options: choices,
          validation: 'single'
        }
      ],
      disable_back: false,
      disable_forward: false,
      skip: false,
    }, {
      id: 'summary',
      context: 'devFlow',
      title: '',
      fields: [{
        'id': 'summary',
        'name': 'Controlla i tuoi dati',
        'type': 'summary',
      }],
      output: 'https://injest.playmoove.com/index.php?channel=flow-test|POST',
      output_format: {
        name: 'name',
        surname: 'surname',
        date: 'date',
        email: 'email',
        phone: 'phone',
        string: 'string',
        number: 'number',
        location: 'location',
      },
    },
  ],
  auto_open: 0,
  dialog: 0,
  loading_message: 'introducing bugs to fix...',
}

export default {
  driver,
  vehicleSlot,
  reservation,
  bookRequest,
  devFlow,
}
